/* eslint-disable no-console,no-undef, camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {/*EventsSection,*//*SectionOption,*/ PersonalInfoSection, ImageAndProfile, InformationContainer, EditButton, ContactData,
  TextContainer, RewardContainer, PageData, WellnessChampionTitle/*UploadContainer*/, BiometricButton
} from './styles';
import { UserName, AoiBar, ImgDiv/*,EmptyButton*/ } from '../SideBar/styles';
import { Button, ButtonNew } from '../PeopleHomeV2/styles';
import { InformationWrapper, EmployeeInformation, LevelText, CelebrationDate } from '../PeopleGridView/styles';
import Image from '../Image';
import WellnessInitiatives from './WellnessInitiatives';
import SocialRecognition from './SocialRecognition';

import { getNewDate, findMonth, getPermissionStatus } from '../../utils/methods';
import { ImageUrl } from '../../utils/constants';
//import { WorkAnniversary, Birthday } from '../SvgComponents';
import { withRouter } from 'react-router-dom'
import { isNull } from 'lodash';
import { imgPath } from '../../utils/constants';
import { withTranslation } from 'react-i18next';
import { unFriendUser, sendFriendRequest } from '../../redux/actions';
import { connect } from 'react-redux';


const EmployeeContent = ({ employee, searchAmigo, companyId, userModalPopup, userPermissions, deleteUserAction,
  onToggleRolesPopup, unFriendUser, sendFriendRequest ,Admin , history }) => {
  // const renderEventsSection = () => (
  //   <EventsSection>
  //     {/* <SectionTitle>Recognitions</SectionTitle> */}
  //     <div>
  //       {/* <Birthday color="#fd5d65" /> */}
  //       <SectionOption>Birthday:</SectionOption>
  //       <CelebrationDate bColor={"bColor"}>
  //         {`${findMonth(parseInt(getNewDate(employee.birthday).format('M'))-1)} ${getNewDate(employee.birthday).format('D')}`}</CelebrationDate>
  //     </div>
  //     <div>
  //       {isNull(employee.work_anniversary_date) ? null : <div>
  //         {/* <WorkAnniversary viewBox={'0 0 33 36'} /> */}
  //         <SectionOption bgColor={"bgColor"}>Work Anniversary:</SectionOption>
  //         <CelebrationDate>{`${findMonth(parseInt(getNewDate(employee.work_anniversary_date).format('M'))-1)} 
  //         ${getNewDate(employee.work_anniversary_date).format('D')}, ${getNewDate(employee.work_anniversary_date).format('Y')}`}</CelebrationDate>
  //       </div>}
  //       {/* <div>
  //         <JobWellDone color="#93bf3e" />
  //         <SectionOption>Job Well Done:</SectionOption>
  //         <span>{employee.jobsDone}</span>
  //       </div>
  //       <div>
  //         <ThankYou color="#159fc9" />
  //         <SectionOption>Thank You:</SectionOption>
  //         <span>{employee.thankYou}</span>
  //       </div>
  //       <div>
  //         <Congrats color="#fbad19" />
  //         <SectionOption>Congrats:</SectionOption>
  //         <span>{employee.congrats}</span>
  //       </div> */}
  //     </div>
  //   </EventsSection>
  // );

  // const renderContactInfoSection = () => (
  //   <ContactInfoSection>
  //     <SectionTitle>contact info</SectionTitle>
  //     <div>
  //       {/* <div>Office tel:<span></span></div> */}
  //       <div>Location: <div className="content">{employee.location || 'NA'}</div></div>
  //       <div>Department: <div className="content">{employee.department || 'NA'}</div></div>
  //       <div>Email: <div className="content">{employee.mail || 'NA'}</div></div>
  //     </div>
  //     <div>
  //       {/* <StyledIcon color="#007bb5">
  //         <i className="fab fa-linkedin" />
  //       </StyledIcon>
  //       <StyledIcon color="#00abed">
  //         <i className="fab fa-twitter" />
  //       </StyledIcon>
  //       <StyledIcon color="#eb407a">
  //         <i className="fa fa-slack" aria-hidden="true" />
  //       </StyledIcon>
  //       <StyledIcon color="#00b5eb">
  //         <i className="fab fa-skype" />
  //       </StyledIcon> */}
  //     </div>
  //   </ContactInfoSection>
  // );

  const renderAddDeleteButton = () => (
    <div style={{ "width": "100%", "height": "100%", "display": "flex", "flexWrap": "wrap", "padding": "25px 15px" }}>
      {(getPermissionStatus("Assign Roles", userPermissions)) && <Button
        onClick={() => onToggleRolesPopup(employee['uid'])}
        marginTop={'10px'}
        addButton={'addButton'}
        bgColor={'#FD7175'}
        fontFamily={'Rubik-Medium'}

      >
        Add User Roles
      </Button>}
      {!searchAmigo && (getPermissionStatus("Delete user", userPermissions)) &&
        <Button

          addButton={'addButton'}
          colorButton={'colorButton'}
          bgColor={'rgba(156, 156, 156, 0.1)'}
          enabled={employee['is_user_deleted'] && employee['is_user_deleted']}
          marginTop="10px"
          onClick={deleteUserAction}
          fontFamily={'Rubik-Medium'}
          margintop={'10px'}
          style={{ "marginTop": "10px" }}
        >
          Delete Profile
        </Button>
      }
    </div>
  );

  const renderPersonalInformation = () => (
    <PersonalInfoSection>
      {/* <SectionTitle>CONTACT INFO</SectionTitle> */}
      {/*<div>*/}
      {/*<div>{employee.gender && 'Sex:'}</div>*/}
      {/*<div>{employee.birthday && 'Birthday:'}</div>*/}
      {/*<div>{employee.city && 'City:'}</div>*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*<div>{employee.gender}</div>*/}
      {/*<div>{`${findMonth(parseInt(getNewDate(employee.birthday).format('M'))-1)} ${getNewDate(employee.birthday).format('D')}`}</div>*/}
      {/*<div>{employee.city}</div>*/}
      {/*</div>*/}
      <InformationContainer>
        <p>Email:</p>
        <PageData>{employee.mail}</PageData>
      </InformationContainer>
      {employee.location ? <InformationContainer>
        <p>Location:</p>
        <PageData>{employee.location}</PageData>
      </InformationContainer> : null}
      {employee.department ? <InformationContainer>
        <p>Department:</p>
        <PageData>{employee.department}</PageData>
      </InformationContainer> : null}
      <InformationContainer>
        <p>Birthday:</p>
        <PageData>
          <CelebrationDate bColor={"bColor"}>
            {`${findMonth(parseInt(getNewDate(employee.birthday).format('M')) - 1)} ${getNewDate(employee.birthday).format('D')}`}</CelebrationDate>
        </PageData>
      </InformationContainer>
      <InformationContainer>
        <p>Work Anniversary:</p>
        <PageData>
          {isNull(employee.work_anniversary_date) ? null : <div>
            <CelebrationDate>{`${findMonth(parseInt(getNewDate(employee.work_anniversary_date).format('M')) - 1)} 
          ${getNewDate(employee.work_anniversary_date).format('D')}, ${getNewDate(employee.work_anniversary_date).format('Y')}`}</CelebrationDate>
          </div>}
        </PageData>
      </InformationContainer>
      {/* <InformationContainer>
        {renderEventsSection()}
      </InformationContainer> */}
      {/* <div>
        { !searchAmigo && (getPermissionStatus("Add wellness champion", userPermissions)) && !Admin &&
            <Button
              onClick={() => setOrRemoveRole(employee['uid'], employee['is_champion'])}
            >
              <span className={`glyphicon glyphicon-${ !employee['is_champion'] ? 'plus' : 'minus'}`} >
              </span>Wellness Champion
            </Button>
        }
        {(getPermissionStatus("Assign Roles", userPermissions)) && <Button
          onClick={() => onToggleRolesPopup(employee['uid'])}
          marginTop={'10px'}
        >
          <span className={`glyphicon glyphicon-${'plus'}`} ></span>Add User Roles
        </Button>}
        { !searchAmigo && (getPermissionStatus("Delete user", userPermissions)) &&
          <Button
            bgColor={'#FF0000'}
            disabled={employee['is_user_deleted'] && employee['is_user_deleted']}
            marginTop="10px"
            onClick={deleteUserAction}
          >
            Delete
          </Button>
        }
        { (getPermissionStatus("Upload biometric form", userPermissions)) && (!searchAmigo && Admin && !employee.physician_form && employee.show_biometric) ?
          <UploadContainer>
            <label htmlFor="biometric-user-admin-file" className="biometric-label">
              UPLOAD BIOMETRIC FORM
              <input
                id="biometric-user-admin-file"
                type="file"
                name="user"
                accept=".pdf"
                multiple={false}
                onChange={(e) => uploadPDFFile(e, employee.uid)}
                onClick={(e) => e.target.files[0] && uploadPDFFile(e, employee.uid)}
              />
            </label>
          </UploadContainer> : ""
        }
        {
          ((getPermissionStatus("Add biometric data", userPermissions)) && !searchAmigo && Admin && employee.show_biometric_form ?
            <Button onClick={() => history.push(`/company/people/${employee.uid}/multi-step/physician-form`)}
              bgColor={'#159fc9'}
              marginTop="5px"
            >
              ADD USER BIOMETRIC
            </Button>:  null
          )
        }
        {!searchAmigo && Admin && employee.show_biometric && employee.physician_form && employee.form_available ?
          <UploadContainer>
            <label
              htmlFor="file"
              className="biometric-label"
              onClick={() => showPDFData(employee.uid)}
            >
              VIEW BIOMETRIC FORM
            </label>
          </UploadContainer> : null
        }
      </div> */}
    </PersonalInfoSection>
  );

  // const renderSocialShareIcons = () => (
  //   <ShareIconContainer>
  //     <a>
  //       <img src={'/images/twitter.png'} />
  //     </a>
  //     <a>
  //       <img src={'/images/slack.png'} />
  //     </a>
  //     <a>
  //       <img src={'/images/linkedin.png'} />
  //     </a>
  //     <a>
  //       <img src={'/images/twitter.png'} />
  //     </a>
  //   </ShareIconContainer>
  // )

  return (
    <div>
      <div>
        {
          (employee.is_champion) ?
            <WellnessChampionTitle>Wellness Champion
            </WellnessChampionTitle>
            :
            ''
        }
        <EditButton onClick={() => userModalPopup()}>
          <img src="/public/images/PeoplePage/EditIcon.png" />
        </EditButton>
        <ImageAndProfile>
          <div className="employeeImage">
            <Image image={employee.profile_image} />
          </div>
          {/* <UserName>
            {employee.fullname && employee.fullname}
            <div className="section2">{employee.department}</div>
          </UserName> */}
          <UserName>
            <div className="section1">{employee.fullname && employee.fullname}</div>
            <div className="section2">{employee.department}</div>
            {
              employee.friend === 0 ?
                <ButtonNew fontFamily={'Rubik-Medium'} newBgColorRight={'newBgColor'} profileColor={'profileColor'} onClick={() => sendFriendRequest(employee.uid, employee.request_status)} >add buddy</ButtonNew>
                : employee.friend === 1 ?
                  <ButtonNew fontFamily={'Rubik-Medium'} newBgColors={'newBgColor'} greenProfileColor={'GreenProfileColor'}>buddies</ButtonNew> : employee.friend === 2 ?
                    <ButtonNew fontFamily={'Rubik-Medium'} newBgColors={'newBgColor'} yellowProfileColor={'YellowProfileColor'} margin={'10px'}>pending</ButtonNew>
                    : ""
            }
            {
              employee.friend === 2 ?
                <ButtonNew fontFamily={'Rubik-Medium'} marginLeft={'10px'} newBgColors={'newBgColor'} whiteColor={'whiteColor'} onClick={() => unFriendUser(employee.uid, employee.request_status)} >cancel</ButtonNew>
                : <ButtonNew emptyBg={"emptyBg"} display= {"none"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</ButtonNew>
            }
          </UserName>
        </ImageAndProfile>
        <ContactData>
          {renderPersonalInformation(employee)}
        </ContactData>
        {/* <div className="text_class">
          <p>Wellness Intreset</p>
        </div> */}
        <TextContainer >
          <p>Wellness Interest</p>
        </TextContainer>
        <AoiBar>
          {employee.interests && employee.interests.map((Wellness, index) => (
            index < 5 &&
            <ImgDiv key={index} margin={(index === 0) ? 1 : 0}>
              <img src={`${ImageUrl}/${Wellness.interest_icon}`} />
            </ImgDiv>
          ))}
        </AoiBar>
        {/* <RewardContainer>
          <p>About Roxiel</p>
          <span>{employee.bio}</span>
        </RewardContainer> */}
        <RewardContainer padding={'0px'}>
          <p>Reward</p>
          <EmployeeInformation>
            {employee.total_global_points >= 7000
              ?
              <LevelText color="rgba(40,44,55,0.5)" >
                <img src={`${imgPath}/Rewards/gold.png`} style={{ width: "48px", height: "65px" }} />
              </LevelText>
              :
              employee.total_global_points >= 3500
                ?
                <LevelText color="rgba(40,44,55,0.5)">
                  <img src={`${imgPath}/Rewards/silver.png`} style={{ width: "48px", height: "65px" }} />
                </LevelText>
                :
                <LevelText color="rgba(40,44,55,0.5)">
                  <img src={`${imgPath}/Rewards/bronze.png`} style={{ width: "48px", height: "65px" }} />
                </LevelText>
            }
          </EmployeeInformation>

        </RewardContainer>
        <EmployeeInformation>
          {employee.user_type === 0 && employee.department &&
            <InformationWrapper active={1}>
              {/* <div>{employee.department}</div> */}
            </InformationWrapper>
          }
          {/* {
            (employee.user_type === 1 || employee.user_type === 2) ?
              <InformationWrapper active={1}>
                <div>{employee.user_type === 1 ? 'Spouse' : 'Dependent'}</div>
              </InformationWrapper> : null
          } */}
          {employee.user_type === 0 && employee.location &&
            <InformationWrapper active={1}>
              {/* <div>{employee.location}</div> */}
            </InformationWrapper>
          }
          {/* { (employee.is_wellness_admin || employee.is_champion) ?
            <LevelText color="#159fc9" marginTop="15px" marginBottom="10px">
              {employee.is_wellness_admin ? 'Wellness Admin' : employee.is_champion ? 'Wellness Champion' : null}
            </LevelText>
            :
            null
          } */}
        </EmployeeInformation>
      </div>
      {/* {renderEventsSection()} */}
      {/*{renderContactInfoSection()}*/}
      {!searchAmigo && <WellnessInitiatives employee={employee} companyId={companyId} />}
      <RewardContainer paddingNew= {"0px 15px 15px 15px"} >
        <p >Recognitions</p>
        {!searchAmigo && <SocialRecognition shoutoutCount={employee.shoutout_count} />}
      </RewardContainer>
      {renderAddDeleteButton()}
      {(getPermissionStatus("Add biometric data", userPermissions)) && !searchAmigo && Admin && employee.show_biometric_form &&
      <BiometricButton>
        <button onClick={() => history.push(`/company/people/${employee.uid}/multi-step/physician-form`)}>
          ADD USER BIOMETRIC
        </button>
      </BiometricButton>}
      {/* {renderPersonalInformation(employee)} */}
      {/* {renderSocialShareIcons()} */}
    </div>
  );
};

EmployeeContent.propTypes = {
  employee: PropTypes.object.isRequired,
  setOrRemoveRole: PropTypes.func,
  deleteUserAction: PropTypes.func,
  searchAmigo: PropTypes.bool,
  uploadPDFFile: PropTypes.func,
  Admin: PropTypes.bool,
  companyId: PropTypes.number,
  history: PropTypes.object,
  showPDFData: PropTypes.func,
  userPermissions: PropTypes.array,
  onToggleRolesPopup: PropTypes.func,
  userModalPopup: PropTypes.func,
  t: PropTypes.func,
  unFriendUser: PropTypes.func,
  sendFriendRequest: PropTypes.func

};


const mapStateToProps = (state) => ({
  removeBuddyDetails: state.social.removeBuddy,
  sentRequest: state.social.sentRequest
});

const mapDispatchToProps = (dispatch) => ({
  sendFriendRequest: (data) => dispatch(sendFriendRequest(data)),
  unFriendUser: (ID, bool) => dispatch(unFriendUser(ID, bool))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmployeeContent)));