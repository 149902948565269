import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SectionTitle, WellnessInitiativeSection, InitiativeTitle, WellnessInitiativeContainer, WellnessInitiativeRow, TickWrapper, WellnessPoint} from './styles';

import {WellnessEvent, RestingHeartRate, EyeRetina} from '../SvgComponents';
import HealthRisk from '../SvgComponents/HealthRisk';
import moment from 'moment';
import isNull from "lodash/isNull";
import Image from '../Image';
import { withTranslation } from 'react-i18next';

class WellnessInitiatives extends Component {

  getTickmark = (data) => {
    if(!data) {
      return <TickWrapper>
        {/* <img src='/public/images/wrong-tick-mark.png' alt="wrong-tick" /> */}
        <img src='/public/images/CompanyDashBoardV2/NewIcon/WrongSub.png' alt="wrong-tick" />
      </TickWrapper>
    }
    else {
      return <TickWrapper rightTick={1}>
        {/* <img src='/public/images/right-tick-mark.png' alt="right-tick" /> */}
        <img src='/public/images/CompanyDashBoardV2/NewIcon/ThickVector.png' alt="right-tick" /> 
      </TickWrapper>
    }
  }
  render() {
    const { employee, userProfile, userInitiatives, t} = this.props;
    return (
      <div>
        {
          <WellnessInitiativeSection>
            <WellnessInitiativeContainer>
              <SectionTitle display='block' marginLeft='10px'>{t("Wellness Initiatives")} - {moment().format('YYYY')}</SectionTitle>
              {
                userProfile ? userInitiatives.map((data, index) => (
                  <WellnessInitiativeRow key={index}>
                    <div>
                      <div className="fixedIcon">
                        <Image image={data.logo}/>
                      </div>
                      <div className="secondGrid">
                        <InitiativeTitle wellnessInitiative={1}>{t(data.name)}</InitiativeTitle>
                        <WellnessPoint>{`+${data.points} ${t("pts")}`}</WellnessPoint>
                      </div>
                      <div className="thirdGrid">
                        {this.getTickmark(data.is_taken)}
                      </div>
                    </div>
                  </WellnessInitiativeRow>
                )) : <div>
                  <WellnessInitiativeRow>
                    <div>
                      <div className="fixedIcon">
                        <HealthRisk color="#159fc9" viewBox="0 0 28 20" />
                      </div>
                      <div className="secondGrid">
                        <InitiativeTitle wellnessInitiative={1}>{t('Health Risk Assessment')}</InitiativeTitle>
                        <WellnessPoint style={{"color": "#9FC989"}}>+500 {t("pts")}</WellnessPoint>
                      </div>
                      <div className="thirdGrid">
                        {this.getTickmark(employee.is_wba_taken)}
                      </div>
                    </div>
                  </WellnessInitiativeRow>
                  { employee.show_biometric ?
                    <WellnessInitiativeRow>
                      <div>
                        <div className="fixedIcon">
                          <RestingHeartRate color="#fd5d65" />
                        </div>
                        <div className="secondGrid">
                          <InitiativeTitle>{t("Biometric Screening")}</InitiativeTitle>
                          <WellnessPoint style={{"color": "#69C2FF"}}>+500 {t("pts")}</WellnessPoint>
                        </div>
                        <div className="thirdGrid">
                          {this.getTickmark(employee.physician_form)}
                        </div>
                      </div>
                    </WellnessInitiativeRow> : null
                  }
                  <WellnessInitiativeRow>
                    <div>
                      <div className="fixedIcon">
                        <WellnessEvent color="#159fc9" viewBox="0 0 28 20" />
                      </div>
                      <div className="secondGrid">
                        <InitiativeTitle>{t("Earn 250 Social Points")}</InitiativeTitle>
                        <WellnessPoint style={{"color": "#FD7175"}}>+250 {t("pts")}</WellnessPoint>
                      </div>
                      <div className="thirdGrid">
                        {this.getTickmark(isNull(employee.earned_250_social_points) ? 0 : employee.earned_250_social_points )}
                      </div>
                    </div>
                  </WellnessInitiativeRow>
                  <WellnessInitiativeRow>
                    <div>
                      <div className="fixedIcon">
                        <EyeRetina color="#159fc9" viewBox="0 0 28 20" />
                      </div>
                      <div className="secondGrid">
                        <InitiativeTitle>{t("Earn 250 Education Points")}</InitiativeTitle>
                        <WellnessPoint style={{"color": "#9D87A9"}}>+250 pts</WellnessPoint>
                      </div>
                      <div className="thirdGrid">
                        {this.getTickmark(isNull(employee.earned_250_Wellness_points)? 0 : employee.earned_250_Wellness_points)}
                      </div>
                    </div>
                  </WellnessInitiativeRow>
                </div>
              }
            </WellnessInitiativeContainer>
          </WellnessInitiativeSection>
        }
      </div>
    );
  }   
}

WellnessInitiatives.propTypes = {
  employee: PropTypes.object,
  companyId: PropTypes.number,
  userProfile: PropTypes.number,
  userInitiatives: PropTypes.array,
  t: PropTypes.func
};

export default (withTranslation()(WellnessInitiatives));