import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { RecognitionContainer, InitiativeSection,/*InitiativeTest, RewardContainer*/ } from './styles';

import { ImageUrl } from '../../utils/constants';
import { round, isUndefined, isNull } from 'lodash';

class WellnessInitiatives extends Component {

  render() {
    const { shoutoutCount } = this.props; 
    return (
      <RecognitionContainer length={shoutoutCount && shoutoutCount.length > 3 ? round(shoutoutCount.length / 3) : '1'}>
        {/* <RewardContainer>
          <p>Recognitions</p>
        </RewardContainer> */}
        <div>
          {shoutoutCount && shoutoutCount.length > 0 ? shoutoutCount.map((data, index) => (
            <InitiativeSection key={index}>
              <div>
                <div>
                  <div>
                    <img src={`${ImageUrl}/${data.icon}`} />
                  </div>
                  <div>
                    {data.name}
                  </div>  
                </div>
                <div>
                  {isUndefined(data) || isNull(data) ? '' : data.count}
                </div>
              </div>
            </InitiativeSection>
          )) : null}
        </div>
      </RecognitionContainer>
    );
  }   
}

WellnessInitiatives.propTypes = {
  shoutoutCount: PropTypes.array.isRequired
};

export default WellnessInitiatives;